import React, { useState, useEffect, useRef } from "react";
import { FaPlay } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// Import your countryCodes (which contains iso2, dial_code, flagUrl, etc.)
import countryCodes from "../../Data/phonenocode.json"; 
// Adjust the path if needed

import "../../Styles/Modal.css"; // Ensure this file exists

const AboutUs = ({ visitData, ...props }) => {
  // State to control the visibility of the video modal
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // State to control the visibility of the "Get a Quote" modal
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => setIsOpen2(false);

  // Function possibly used for a "download" flow
  function downloadlocal() {
    props.setdown(true);
    closeModal();
  }

  // React Router navigation
  const navigate = useNavigate();

  // State to track form errors
  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });

  // State to store form data (excluding phone)
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: "",
  });

  // State to manage selected country and local phone number
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");

  // State to control the visibility of the country dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);
  const [isSubmitting, setIsSubmitting] = useState(false);  

  // Ref to the country dropdown container to detect outside clicks
  const dropdownRef = useRef(null);

  // On mount, determine the default selected country from visitData or fallback to 'IN'
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);
  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle changes in text input fields
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle changes in the phone number input field
  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  // Toggle the visibility of the country dropdown
  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  // Handle the selection of a country from the dropdown
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close the dropdown after selection
  };

  // Validate email using a simple regex pattern
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validate and format the phone number using libphonenumber-js
  const validateAndFormatPhoneNumber = (fullNumber, countryIso) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, countryIso);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (error) {
      console.error("Error validating phone number:", error);
      return null;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    // Combine dial_code + local phone to get the full number
    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhoneNumber(fullNumber, selectedCountry?.iso2);

    // Validate phone number
    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    // Validate email
    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        // Construct the payload with formatted phone number
        const payload = {
          ...formData,
          Mobile: formattedPhone, // Store phone in E.164 format
          ...visitData,
        };

        // Send POST request to the server
        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );


        // Trigger Google Tag Manager (GTM) event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });

        // Trigger Zoho PageSense event
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");

        // Optional: Perform additional actions like downloading
        props.setdown?.(true);
        closeModal2();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  // Effect to handle closing the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close the dropdown if click is outside
      }
    };

    // If the dropdown is open, add the event listener
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the dropdown is closed or component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // If the selected country isn't loaded yet, show a loader
  if (!selectedCountry) {
    return <div>Loading country data...</div>;
  }

  return (
    <>
      <section className="homeaboutus">
        <div className="sectionContainer">
          {/* LEFT SECTION (Video Modal) */}
          <div className="leftabout">
            <img
              className="leftaboutpic"
              alt="Nessco Team"
              src="https://www.nesscoindia.com/Assets/images/resource/nessco-team.webp"
            />
            <div className="leftaboutpic2">
              <div className="aboutimagesection"></div>
              <button className="playerbtn" aria-label="Player Button" onClick={openModal}>
                <FaPlay />
              </button>
              {isOpen && (
                <div className="modal">
                  <button className="close-button" onClick={closeModal}>
                    &times;
                  </button>
                  <iframe
                    width={1000}
                    height={500}
                    id="fancybox-frame1715329533515"
                    name="fancybox-frame1715329533515"
                    className="fancybox-iframe modal-content"
                    frameBorder="0"
                    vspace="0"
                    hspace="0"
                    allowFullScreen
                    allowTransparency
                    src="//www.youtube.com/embed/e-rwkwTE8P4?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1"
                    scrolling="no"
                    title="Nessco Team Video"
                  />
                </div>
              )}
            </div>
          </div>

          {/* RIGHT SECTION (Texts & "Get a Quote" Modal) */}
          <div className="rightabout">
            {/* Original HEADINGS & PARAGRAPHS */}
            <p style={{ margin: "1rem 0" }} className="aboutusbtn mm">
              ABOUT US
            </p>
            <h1 className="aboutheading">
              Paper Cup Machine <span> Manufacturer & Exporter</span>
            </h1>
            <p className="aboutpara">
              Nessco India is an international paper cup machine manufacturing
              company with a trusted brand name since 1978 in domestic as well
              as international markets. Since then, NesscoIndia has widened its
              business globally in the whole Disposable Paper Packaging
              Industry.
            </p>
            <h2 className="aboutheading small">CLAIM TO FAME</h2>
            <p className="aboutpara">
              We are known for manufacturing and globally exporting various
              paper packaging machines including Cups, Glass, Straws and Plate
              Machines. We are focused on innovating the disposable
              manufacturing industry with continuous technology upgradation and
              improvisation for sustainable development.
            </p>

            <div className="aboutbtnsection">
              {/* "Get a Quote" modal */}
              {isOpen2 && (
                <div className="modal">
                  <div className="modalcard">
                    <button className="close-button" onClick={closeModal2}>
                      &times;
                    </button>
                    <div className="leftmodal">
                      <img
                        src="https://www.nesscoindia.com/Assets/images/resource/popup.webp"
                        alt="Popup"
                      />
                    </div>
                    <div className="rightmodal">
                      <h3 className="modalheading">
                        Request for details to receive a call back
                      </h3>
                      <p className="modaldesc">
                        Enter your details to receive a call back
                      </p>

                      {/* NAME */}
                      <input
                        value={formData.Last_Name}
                        onChange={handleChange}
                        name="Last_Name"
                        type="text"
                        required
                        placeholder="Enter your Name*"
                        className="modalinp"
                      />

                      {/* EMAIL */}
                      <input
                        value={formData.Email}
                        onChange={handleChange}
                        name="Email"
                        placeholder="Email"
                        className="modalinp"
                        type="email"
                      />
                      {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

                      {/* Country Dropdown and Phone Input */}
                      {/* Country Dropdown and Phone Input */}
<div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', position: 'relative', width: '100%' }}>
  <div
    style={{
      display: 'flex',
      gap: '0.5rem',
      width: '100%',
      alignItems: 'center',
    }}
  >
    {/* Country Dropdown */}
    <div
      className="modalinp"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        cursor: 'pointer',
        flex: '0 0 40%',
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '40px',
        padding: '0 12px',
      }}
      // onClick={toggleDropdown}
      onClick={() => setShowDropdown(!showDropdown)}
      ref={dropdownRef} // Attach the ref here
    >
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <img
          src={selectedCountry.flagUrl}
          alt={`${selectedCountry.iso2} flag`}
          style={{ width: '20px', height: '14px', objectFit: 'cover' }}
        />
        <span>{selectedCountry.iso2}</span>
        <span>({selectedCountry.dial_code})</span>
      </div>
      <span>&#x25BC;</span>
      {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
    </div>

    {/* Local Phone Input */}
    <input
      value={localPhone}
      onChange={handlePhoneChange}
      placeholder="Phone Number*"
      required
      className="modalinp"
      type="text"
      style={{
        flex: 1,
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '40px',
        padding: '0 12px',
      }}
    />
  </div>

  {/* Phone Number Error Message */}
  {errors.phoneNumber && (
    <p style={{ color: 'red', fontSize: '0.9rem', margin: '0.3rem 0 0' }}>
      {errors.phoneNumber}
    </p>
  )}
</div>

                     

                      {/* Submit Button */}
                      {isSubmitting ? (
                     <button className="headerbtn x" style={{ padding: "2rem 3rem" }} disabled>
                       <p className="headerbtncon">Submitting...</p>
                     </button>
                     ) : (
                     <button onClick={handleSubmit} className="headerbtn x" style={{ padding: "2rem 3rem" }}>
                       <p className="headerbtncon">Get a Quote !</p>
                       <FaArrowRightLong className="headerbtnarrow" style={{ fontSize: "1.5rem" }}/>
                     </button>
                     )}
                    </div>
                  </div>
                </div>
              )}

              {/* Button to open "Get a Quote" modal */}
              <button
                onClick={openModal2}
                className="headerbtn"
                style={{ padding: "1.5rem 2rem", display: "flex", alignItems: "center", gap: "0.5rem", cursor: "pointer" }}
              >
                <p className="headerbtncon">Get a Quote !</p>
                <FaArrowRightLong
                  className="bannerbtnarrow"
                  style={{ fontSize: "1.5rem" }}
                />
              </button>

              {/* Signature image */}
              <img
                className="signatureimg"
                alt="Signature"
                src="https://www.nesscoindia.com/Assets/images/resource/signature.webp"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
