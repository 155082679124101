import React, { useState, useEffect, useRef } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";
import BannerContent from "./BannerContent";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// Import your country code data
import countryCodes from "../../Data/phonenocode.json";
// Ensure this JSON has objects with: { "iso2": "...", "dial_code": "...", "flagUrl": "..." }

export default function App({ visitData, ...props }) {
  // State to control the visibility of the modal
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // For navigation after successful form submission
  const navigate = useNavigate();

  // State to track form errors
  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });

  // State to store form data (excluding phone)
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: "",
  });

  // State to manage selected country and local phone number
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");
  // State to control the visibility of the country dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);

  // Ref to the dropdown container to detect outside clicks
  const dropdownRef = useRef(null);

  // On mount, determine the default selected country from visitData or fallback to 'IN'
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);

  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle changes in text input fields
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle changes in the phone number input field
  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  // Toggle the visibility of the country dropdown
  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  // Handle the selection of a country from the dropdown
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close the dropdown after selection
  };

  // Validate email using a simple regex pattern
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validate and format the phone number using libphonenumber-js
  const validateAndFormatPhoneNumber = (fullNumber, countryIso) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, countryIso);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (error) {
      console.error("Error validating phone number:", error);
      return null;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    // Combine dial_code + local phone to get the full number
    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhoneNumber(
      fullNumber,
      selectedCountry?.iso2
    );

    // Validate phone number
    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    // Validate email
    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        // Construct the payload with formatted phone number
        const payload = {
          ...formData,
          Mobile: formattedPhone, // Store phone in E.164 format
          ...visitData,
        };

        // Send POST request to the server
        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        // Trigger Google Tag Manager (GTM) event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });

        // Trigger Zoho PageSense event
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

        navigate("/thank-you/");

        // Optional: Perform additional actions like downloading
        props.setdown?.(true);
        closeModal();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Effect to handle closing the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // If the dropdown is open, add the event listener
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the dropdown is closed or component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // If the selected country isn't loaded yet, show a loader
  if (!selectedCountry) {
    return <div>Loading country data...</div>;
  }

  return (
    <>
      <section className="mySwiper">
        {/* Background layers for styling */}
        <div className="imglayers">
          <div className="imglayer2"></div>
          <div className="imglayer1"></div>
          <div className="imglayer3"></div>
          <div className="imglayer4"></div>
        </div>

        {/* Banner content */}
        <div className="bannerslide">
          <BannerContent />

          {/* "Get A Quote" button that opens the modal */}
          <button
            onClick={openModal}
            className="bannerbtn"
            style={{ marginTop: "2rem" }}
          >
            <p className="bannerbtncon">Get A Quote</p>{" "}
            <FaArrowRightLong
              className="bannerbtnarrow"
              style={{ fontSize: "1.5rem" }}
            />
          </button>

          {/* Modal for the form */}
          {isOpen && (
            <div className="modal">
              <div className="modalcard">
                {/* Close button */}
                <button className="close-button" onClick={closeModal}>
                  &times;
                </button>

                {/* Left side of the modal with an image */}
                <div className="leftmodal">
                  <img
                    src="https://nesscoindia.com/Assets/images/resource/popup.webp"
                    alt="Popup"
                  />
                </div>

                {/* Right side of the modal with the form */}
                <div className="rightmodal">
                  <h1 className="modalheading">
                    Request for details to receive a call back
                  </h1>
                  <p className="modaldesc">
                    Enter your details to receive a call back
                  </p>

                  {/* Name Input */}
                  <input
                    value={formData.Last_Name}
                    onChange={handleChange}
                    name="Last_Name"
                    type="text"
                    required
                    placeholder="Enter your Name*"
                    className="modalinp"
                  />

                  {/* Email Input */}
                  <input
                    value={formData.Email}
                    onChange={handleChange}
                    name="Email"
                    placeholder="Email"
                    className="modalinp"
                    type="email"
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email}</p>
                  )}

                  {/* Country Dropdown and Phone Input */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {/* Country Dropdown */}
                      <div
                        className="modalinp"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          position: "relative",
                          cursor: "pointer",
                          flex: "0 0 40%",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          height: "40px",
                          padding: "0 12px",
                        }}
                        // onClick={toggleDropdown}
                        ref={dropdownRef} // Attach the ref here
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={selectedCountry.flagUrl}
                            alt={`${selectedCountry.iso2} flag`}
                            style={{
                              width: "20px",
                              height: "14px",
                              objectFit: "cover",
                            }}
                          />
                          <span>{selectedCountry.iso2}</span>
                          <span>({selectedCountry.dial_code})</span>
                        </div>
                        <span>&#x25BC;</span>
                        {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
  </div>

  {/* Local Phone Input */}
  <input
    value={localPhone}
    onChange={handlePhoneChange}
    placeholder="Phone Number*"
    required
    className="modalinp"
    type="text"
    style={{
      flex: 1,
      border: "1px solid #ccc",
      borderRadius: "4px",
      height: "40px",
      padding: "0 12px",
    }}
  />
</div>

                    {/* Phone Number Error Message */}
                    {errors.phoneNumber && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "0.9rem",
                          margin: "0.3rem 0 0",
                        }}
                      >
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>

                  {/* Submit Button */}
                  {isSubmitting ? (
                    <button
                      className="headerbtn x"
                      style={{ padding: "2rem 3rem" }}
                      disabled
                    >
                      <p className="headerbtncon">Submitting...</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="headerbtn x"
                      style={{ padding: "2rem 3rem" }}
                    >
                      <p className="headerbtncon">Get a Quote !</p>
                      <FaArrowRightLong
                        className="headerbtnarrow"
                        style={{ fontSize: "1.5rem" }}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
