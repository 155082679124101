import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowRightLong,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoIosMenu } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { FaAngleDown, FaFacebookSquare } from "react-icons/fa";
import axios from "axios";

// 1) Import parsePhoneNumberFromString from libphonenumber-js
import { parsePhoneNumberFromString } from "libphonenumber-js";
// 2) Import your country code data
import countryCodes from "../../Data/phonenocode.json"; // Adjust path if needed

// (We assume your existing lists are unchanged)
const list1 = [
  {
    id: 6,
    name: "Servo Driven Paper Cup Machine",
    link: "/product/servo-driven-paper-cup-machine/",
  },
  {
    id: 1,
    name: "Paper Glass Machine",
    link: "/product/paper-glass-making-machine/",
  },
  {
    id: 2,
    name: "Paper Disposable Cup Machine",
    link: "/product/disposable-paper-cup-making-machine/",
  },
  {
    id: 3,
    name: "High Speed Paper Cup Machine",
    link: "/product/high-speed-paper-cup-making-machine/",
  },
  {
    id: 4,
    name: "Paper Handle Cup Machine",
    link: "/product/paper-handle-cup-making-machine/",
  },
  {
    id: 5,
    name: "Ripple wall Cup Machine",
    link: "/product/ripple-wall-cup-machine/",
  },
  {
    id: 6,
    name: "Paper Dome Lid Cup Machine",
    link: "/product/paper-dome-lid-machine/",
  },
];
const list2 = [
  {
    id: 1,
    name: "Paper Bowl Machine",
    link: "/product/paper-container-bowl-making-machine/",
  },
  {
    id: 2,
    name: "Paper Salad Bowl Machine",
    link: "/product/salad-bowl-making-machine/",
  },
  {
    id: 4,
    name: "Paper Lid Machine",
    link: "/product/paper-lid-making-machine/",
  },
  {
    id: 5,
    name: "Paper Cutlery Machine",
    link: "/product/paper-cutlery-machine/",
  },
  {
    id: 3,
    name: "Paper Bucket Machine ( Pop Corn / Chicken Tub Mahchine )",
    link: "/product/pop-corn-tub-making-machine/",
  },
];
const list3 = [
  {
    id: 5,
    name: "Paper Plate Machine",
    link: "/product/paper-plate-making-machine/",
  },
  {
    id: 3,
    name: "Lunch Box Forming Machine",
    link: "/product/lunch-box-forming-machine/",
  },
  {
    id: 4,
    name: "Carton Erecting Machine",
    link: "/product/carton-erecting-machine/",
  },
  {
    id: 2,
    name: "Octagonal Box Forming Machine",
    link: "/product/octagonal-box-forming-machine/",
  },
  {
    id: 1,
    name: "Rectangular Flat Bottom Bowl Machine",
    link: "/product/rectangle-flat-bottom-bowl-machine/",
  },
];
const list4 = [
  {
    id: 1,
    name: "Flexo Printing Machine",
    link: "/product/flexo-printing-machine/",
  },
  {
    id: 2,
    name: "Roll Die Cutting Machine",
    link: "/product/roll-die-cutting-machine/",
  },
  {
    id: 3,
    name: "PE Coating Machine",
    link: "/product/pe-coating-machine/",
  },
  {
    id: 4,
    name: "Paper Straw Machine",
    link: "/product/paper-straw-making-machine/",
  },
  {
    id: 5,
    name: "Ripple Wall Cup Sleeve Glueing Machine",
    link: "/product/ripple-wall-cup-sleeve-glueing-machine/",
  },
  {
    id: 5,
    name: "Paper Sleeve Gluing & Folding Machine",
    link: "/product/paper-sleeve-gluing-folding-machine/",
  },
];

const list5 = [
  {
    id: 1,
    name: "Paper Bag Machine",
    link: "/product/paper-bag-making-machine/",
  },
  {
    id: 1,
    name: "Fully Automatic Paper Bag Machine",
    link: "/product/paper-shopping-bag-making-machine/",
  },
  {
    id: 2,
    name: "Square Bottom paper Bag Machine",
    link: "/product/paper-food-bag-making-machine/",
  },
];

const Header = ({ visitData, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // For the "Inquire Now" modal
  const [isOpenmain, setIsOpenmain] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // Possibly used if you have a "downloadlocal" flow
  function downloadlocal() {}

  // For navigation
  const [navigation, setnavigation] = useState(false);
  function opennavigation() {
    setnavigation(true);
  }
  function closenavigation() {
    setnavigation(false);
    setIsOpenmain(false);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    setIsOpen5(false);
    setIsOpen6(false);
  }

  const navigate = useNavigate();

  // Errors => { phoneNumber: "", email: "" }
  const [errors, setErrors] = useState({
    phoneNumber: "",
    email: ""
  });

  // Basic form fields (excluding phone)
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: "",
  });

  // ====== NEW phone states ======
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
const [filteredCountries, setFilteredCountries] = useState(countryCodes);

  // On mount => pick default from visitData or fallback "IN"
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);

  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // For text fields (name, email, subject, message)
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // For local phone
  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  // Basic email check
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Use parsePhoneNumberFromString => E.164
  const validateAndFormatPhone = (fullNumber, iso2) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, iso2);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (err) {
      console.error("Error validating phone number:", err);
      return null;
    }
  };

  // For the form submission in the modal
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    // Combine dial_code + local phone => parse
    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, selectedCountry?.iso2);

    // Check phone
    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    // Check email
    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      try {
        setIsSubmitting(true);
        // Build payload => store E.164 phone as Mobile
        const payload = {
          ...formData,
          Mobile: formattedPhone,
          visitData
        };
       
       // Close the modal before making the API request
      // if (props.closeModal) {
      //   props.closeModal(); // Ensure this correctly updates the modal state
      // } else {
      //   console.warn("closeModal function not provided or not working.");
      // }

        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        // GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });

        // Zoho PageSense
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
        props.setdown?.(true);
        
        closeModal();
      } catch (error) {
        console.error("Error submitting form:", error);
      }finally {
        setIsSubmitting(false); // Reset the submitting state after the submission process
      }
    }
  };

  // If we haven't picked a default country yet, show a loader
  if (!selectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <>
      <nav className="navbar">
        <div className="navbarcontainer">
          {/* Brand logo */}
          <Link className="navbarlogolink" to={"/"}>
            <img
              src={"https://www.nesscoindia.com/Assets/images/logo.webp"}
              alt="NesscoLogo"
            />
          </Link>

          {/* Main nav items */}
          <div className="navlist">
            <Link className="navlink" to={"/"}>
              HOME
            </Link>
            <Link className="navlink" to={"/about-us/"}>
              ABOUT US
            </Link>

            {/* "OUR MACHINES" nav => on hover => show sub-menu */}
            <div
              className="navlink"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Link
                onClick={() => setIsHovered(false)}
                className="navlink"
                to={"/product/"}
              >
                OUR MACHINES
              </Link>
              {isHovered && (
                <div className="menu-list">
                  <div className="menu-list-container">
                    <div className="menulistitems">
                      <h3 className="menulistheading">Paper Cup Machines</h3>
                      {list1.map((obj, key) => (
                        <Link
                          onClick={() => setIsHovered(false)}
                          className="menulistlinks"
                          key={key}
                          to={obj.link}
                        >
                          {obj.name}
                        </Link>
                      ))}
                    </div>
                    <div className="menulistitems">
                      <h3 className="menulistheading">
                        Paper Container Machines
                      </h3>
                      {list2.map((obj, key) => (
                        <Link
                          onClick={() => setIsHovered(false)}
                          className="menulistlinks"
                          key={key}
                          to={obj.link}
                        >
                          {obj.name}
                        </Link>
                      ))}
                    </div>
                    <div className="menulistitems">
                      <h3 className="menulistheading">
                        Paper Forming Machines
                      </h3>
                      {list3.map((obj, key) => (
                        <Link
                          onClick={() => setIsHovered(false)}
                          className="menulistlinks"
                          key={key}
                          to={obj.link}
                        >
                          {obj.name}
                        </Link>
                      ))}
                    </div>
                    <div className="menulistitems">
                      <h3 className="menulistheading">Other Machines</h3>
                      {list4.map((obj, key) => (
                        <Link
                          onClick={() => setIsHovered(false)}
                          className="menulistlinks"
                          key={key}
                          to={obj.link}
                        >
                          {obj.name}
                        </Link>
                      ))}
                    </div>
                    <div className="menulistitems">
                      <h3 className="menulistheading">Paper Bag Machines</h3>
                      {list5.map((obj, key) => (
                        <Link
                          onClick={() => setIsHovered(false)}
                          className="menulistlinks"
                          key={key}
                          to={obj.link}
                        >
                          {obj.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Link className="navlink" to={"/video/"}>
              VIDEO
            </Link>
            <Link className="navlink" to={"/blog/"}>
              BLOG
            </Link>
            <Link className="navlink" to={"/contact/"}>
              CONTACT US
            </Link>
          </div>

          {/* "Inquire Now!" button => opens modal */}
          <button onClick={openModal} className="headerbtn tt">
            <p className="headerbtncon">Inquire Now !</p>
            <FaArrowRightLong
              className="headerbtnarrow"
              style={{ fontSize: "1.5rem" }}
            />
          </button>

          {/* The "Inquire Now!" modal */}
          {isOpen && (
            <div className="modal">
              <div className="modalcard">
                <button className="close-button" onClick={closeModal}>
                  &times;
                </button>
                <div className="leftmodal">
                  <img
                    src={"https://www.nesscoindia.com/Assets/images/resource/popup.webp"}
                    alt="POPUP"
                  />
                </div>
                <div className="rightmodal">
                  <h1 className="modalheading">
                    Request for details to receive a call back
                  </h1>
                  <p className="modaldesc">
                    Enter your details to receive a call back
                  </p>

                  {/* Name */}
                  <input
                    value={formData.Last_Name}
                    onChange={handleChange}
                    name="Last_Name"
                    type="text"
                    required
                    placeholder="Enter your Name*"
                    className="modalinp"
                  />

                  {/* Email */}
                  <input
                    value={formData.Email}
                    onChange={handleChange}
                    name="Email"
                    placeholder="Email"
                    className="modalinp"
                    type="email"
                  />
                  {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

                  {/* Single-line => Country code + local phone */}
                  <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem", width: "100%" }}>
    <div
      className="modalinp"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        cursor: "pointer",
        flex: "0 0 40%",
      }}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      {selectedCountry && (
        <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
          <img
            src={selectedCountry.flagUrl}
            alt={`${selectedCountry.iso2} flag`}
            style={{ width: "20px", height: "14px", objectFit: "cover" }}
          />
          <span>{selectedCountry.iso2}</span>
          <span>({selectedCountry.dial_code})</span>
        </div>
      )}
      <span>&#x25BC;</span>
      {showDropdown && (
        <div
          style={{
            position: "absolute",
            background: "#fff",
            border: "1px solid #ccc",
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            top: "100%",
            left: 0,
            zIndex: 9999,
          }}
        >
          <input
            type="text"
            placeholder="Search country..."
            value={searchTerm}
            onChange={handleSearch}
            onClick={(e) => e.stopPropagation()}  // Prevent dropdown from closing
            style={{
              width: "100%",
              padding: "0.5rem",
              border: "none",
              outline: "none",
              borderBottom: "1px solid #ccc",
            }}
          />
          <ul style={{ listStyle: "none", margin: 0, padding: "0.5rem" }}>
            {filteredCountries.map((c, idx) => (
              <li
                key={idx}
                onClick={() => {
                  setSelectedCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{ width: "20px", height: "14px", objectFit: "cover" }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))}
            {filteredCountries.length === 0 && <li style={{ padding: "4px 0" }}>No results found</li>}
          </ul>
        </div>
      )}
    </div>

    <div style={{ flex: 1, width: "100%" }}>
      <input
        value={localPhone}
        onChange={handlePhoneChange}
        name="Mobile"
        placeholder="Phone Number*"
        required
        className="modalinp"
        type="text"
      />
    </div>
  </div>
                  {errors.phoneNumber && (
                        <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                      )}

                  {/* Submit */}
                  {isSubmitting ? (
            <button
              className="headerbtn x"
              style={{ padding: "2rem 3rem" }}
              disabled
            >
              <p className="headerbtncon">Submitting...</p>
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="headerbtn x"
              style={{ padding: "2rem 3rem" }}
            >
              <p className="headerbtncon">Get a Quote !</p>
              <FaArrowRightLong
                className="headerbtnarrow"
                style={{ fontSize: "1.5rem" }}
              />
            </button>
          )}
                </div>
              </div>
            </div>
          )}

          {/* Hamburger nav for mobile */}
          <div className="navhamburger active">
            <IoIosMenu onClick={opennavigation} className="hamburger" />
            {navigation && (
              <div className="hamburgermenu">
                <div className="hamburgercontent">
                  <button onClick={closenavigation} className="hamburgerclose">
                    <ImCross />
                  </button>
                  <div className="hamburgercontainer">
                    <div className="hamburgerimgcontainer">
                      <img
                        className="hamburgerimg"
                        src={"https://www.nesscoindia.com//Assets/images/logo.webp"}
                        alt="NesscoLogo"
                      />
                    </div>
                    <div className="hamburgerlinks">
                      <Link
                        className="hamburgerlink"
                        to={"/"}
                        onClick={closenavigation}
                      >
                        HOME
                      </Link>
                      <Link
                        className="hamburgerlink"
                        to={"/about-us/"}
                        onClick={closenavigation}
                      >
                        ABOUT US
                      </Link>
                      {/* The "OUR MACHINES" collapsible in hamburger */}
                      <div className="hamburgerinternallink ">
                        <div className="hamburgerinternalcombo">
                          <h3
                            className="hamburgerlink"
                            onClick={() => setIsOpenmain(!isOpenmain)}
                            style={{ fontWeight: "400" }}
                          >
                            OUR MACHINES
                          </h3>
                          <button
                            onClick={() => setIsOpenmain(!isOpenmain)}
                            className={!isOpenmain ? "hamburgertogglebtn" : " toggled"}
                          >
                            <FaAngleDown />
                          </button>
                        </div>
                        {isOpenmain && (
                          <>
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <Link
                                  to={"product"}
                                  className="hamburgerlink"
                                  onClick={closenavigation}
                                >
                                  All Machines
                                </Link>
                              </div>
                            </div>
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <p
                                  className="hamburgerlink"
                                  onClick={() => setIsOpen1(!isOpen1)}
                                >
                                  Paper Cup Machines
                                </p>
                                <button
                                  onClick={() => setIsOpen1(!isOpen1)}
                                  className={!isOpen1 ? "hamburgertogglebtn" : "hamburgertogglebtn toggled"}
                                >
                                  <FaAngleDown />
                                </button>
                              </div>
                              {isOpen1 && (
                                <div className="hamburgerinternallist">
                                  {list1.map((e, key) => (
                                    <Link
                                      key={key}
                                      className="hamburgerlink"
                                      to={e.link}
                                      onClick={closenavigation}
                                    >
                                      {e.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* Paper Container Machines */}
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <p
                                  className="hamburgerlink"
                                  onClick={() => setIsOpen2(!isOpen2)}
                                >
                                  Paper Container Machines
                                </p>
                                <button
                                  onClick={() => setIsOpen2(!isOpen2)}
                                  className={!isOpen2 ? "hamburgertogglebtn" : "hamburgertogglebtn toggled"}
                                >
                                  <FaAngleDown />
                                </button>
                              </div>
                              {isOpen2 && (
                                <div className="hamburgerinternallist">
                                  {list2.map((e, key) => (
                                    <Link
                                      key={key}
                                      className="hamburgerlink"
                                      to={e.link}
                                      onClick={closenavigation}
                                    >
                                      {e.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* Paper Forming machines */}
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <p
                                  className="hamburgerlink"
                                  onClick={() => setIsOpen3(!isOpen3)}
                                >
                                  Paper Forming machines
                                </p>
                                <button
                                  onClick={() => setIsOpen3(!isOpen3)}
                                  className={!isOpen3 ? "hamburgertogglebtn" : "hamburgertogglebtn toggled"}
                                >
                                  <FaAngleDown />
                                </button>
                              </div>
                              {isOpen3 && (
                                <div className="hamburgerinternallist">
                                  {list3.map((e, key) => (
                                    <Link
                                      key={key}
                                      className="hamburgerlink"
                                      to={e.link}
                                      onClick={closenavigation}
                                    >
                                      {e.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* Other Machines */}
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <p
                                  className="hamburgerlink"
                                  onClick={() => setIsOpen4(!isOpen4)}
                                >
                                  Other Machines
                                </p>
                                <button
                                  onClick={() => setIsOpen4(!isOpen4)}
                                  className={!isOpen4 ? "hamburgertogglebtn" : "hamburgertogglebtn toggled"}
                                >
                                  <FaAngleDown />
                                </button>
                              </div>
                              {isOpen4 && (
                                <div className="hamburgerinternallist">
                                  {list4.map((e, key) => (
                                    <Link
                                      key={key}
                                      className="hamburgerlink"
                                      to={e.link}
                                      onClick={closenavigation}
                                    >
                                      {e.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* Paper Bag Machines */}
                            <div className="hamburgerinternallist">
                              <div className="hamburgerinternalcombo">
                                <p
                                  className="hamburgerlink"
                                  onClick={() => setIsOpen5(!isOpen5)}
                                >
                                  Paper Bag Machines
                                </p>
                                <button
                                  onClick={() => setIsOpen5(!isOpen5)}
                                  className={!isOpen5 ? "hamburgertogglebtn" : "hamburgertogglebtn toggled"}
                                >
                                  <FaAngleDown />
                                </button>
                              </div>
                              {isOpen5 && (
                                <div className="hamburgerinternallist">
                                  {list5.map((e, key) => (
                                    <Link
                                      key={key}
                                      className="hamburgerlink"
                                      to={e.link}
                                      onClick={closenavigation}
                                    >
                                      {e.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      {/* Additional links */}
                      <Link
                        className="hamburgerlink"
                        to={"/video/"}
                        onClick={closenavigation}
                      >
                        VIDEO
                      </Link>
                      <Link
                        className="hamburgerlink"
                        to={"/blog/"}
                        onClick={closenavigation}
                      >
                        BLOG
                      </Link>
                      <Link
                        className="hamburgerlink"
                        to={"/contact/"}
                        onClick={closenavigation}
                      >
                        CONTACT US
                      </Link>
                    </div>

                    {/* Contact details */}
                    <div className="hamburgerdetails">
                      <a
                        href="tel:+91 95494 44484"
                        className="hamburgercontact hh"
                      >
                        +91 95494 44484
                      </a>
                      <a
                        href="mailto:info@nesscoindia.com"
                        className="hamburgercontact hh"
                      >
                        info@nesscoindia.com
                      </a>
                      <a
                        href="https://maps.app.goo.gl/eWURecM5YL7Gt2Kf7"
                        className="hamburgercontact"
                      >
                        E-186, Apparel Park, RIICO Industrial Area, Mahal Road,
                        Jagatpura, Jaipur (Rajasthan) - 302022, INDIA
                      </a>
                    </div>

                    {/* Social icons */}
                    <div className="hamburgersocials">
                      <a
                        className="hamburgersocialicons"
                        href="https://twitter.com/NesscoIndia"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTwitter />
                      </a>
                      <a
                        className="hamburgersocialicons"
                        href="https://www.facebook.com/NesscoPaperCupMachine"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookSquare />
                      </a>
                      <a
                        className="hamburgersocialicons"
                        href="https://www.linkedin.com/company/nesscoindia/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn />
                      </a>
                      <a
                        className="hamburgersocialicons"
                        href="https://www.instagram.com/nesscoindia/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                      </a>
                      <a
                        className="hamburgersocialicons"
                        href="https://www.youtube.com/c/NesscoPaperCupMachine"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
