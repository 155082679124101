import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaAngleRight, FaArrowRightLong } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countryCodes from '../../Data/phonenocode.json'; // Adjust path if needed

const IntroMachine = ({ visitData, heading, description, price, showPrice }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for the dropdown

  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: ""
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);
  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateAndFormatPhone = (fullNumber, iso2) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, iso2);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (error) {
      console.error("Error validating phone number:", error);
      return null;
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, selectedCountry?.iso2);

    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        const payload = {
          ...formData,
          Mobile: formattedPhone,
          visitData
        };

        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });

        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!selectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <section className="intromachine">
      <div className="intromachinecompo">
        <div className="intromachineleft">
          <div className="breadcrumconnections">
            <Link to="/" className="breadcrumconnectiondislink">Home</Link>
            <FaAngleRight className="breadcrumicon2" />
            <Link to="/product" className="breadcrumconnectiondislink">Products</Link>
            <FaAngleRight className="breadcrumicon2" />
            <p className="breadcrumconnectionactivelink">{heading}</p>
          </div>
          <h1 className="intromachineheading">{heading}</h1>
          {showPrice && <h5 className="machine-price">Price Range: {price}</h5>}
          <p className="intromachinedescription">{description}</p>
        </div>

        <div className="intromachineform">
          <div className="intromachinecard">
            <input
              style={{ background: "white" }}
              value={formData.Last_Name}
              onChange={handleChange}
              name="Last_Name"
              placeholder="Full Name*"
              className="intromachineforminputs"
              type="text"
              required
            />
            <div className="validations" style={{ width: "100%" }}>
              <input
                style={{ background: "white" }}
                value={formData.Email}
                onChange={handleChange}
                name="Email"
                placeholder="Email"
                className="intromachineforminputs"
                type="text"
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </div>
            <div className="validations" style={{ width: "100%", display: "flex", gap: "0.5rem" }}>
              <div
                ref={dropdownRef}
                style={{
                  background: "white",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  flex: "0 0 40%",
                }}
                className="intromachineforminputs"
                // onClick={toggleDropdown}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
                  <img
                    src={selectedCountry.flagUrl}
                    alt={`${selectedCountry.iso2} flag`}
                    style={{ width: "20px", height: "14px", objectFit: "cover" }}
                  />
                  <span>{selectedCountry.iso2}</span>
                  <span>({selectedCountry.dial_code})</span>
                </div>
                <span>&#x25BC;</span>
                {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
              </div>
              <div style={{ flex: 1 }}>
                <input
                  style={{ background: "white" }}
                  value={localPhone}
                  onChange={handlePhoneChange}
                  name="Mobile"
                  placeholder="Phone Number*"
                  required
                  className="intromachineforminputs"
                  type="text"
                />
              </div>
            </div>
            {errors.phoneNumber && <p style={{ color: "red" }}>{errors.phoneNumber}</p>}
            <textarea
              style={{ background: "white" }}
              value={formData.Web_Message}
              onChange={handleChange}
              name="Web_Message"
              placeholder="Message"
              className="intromachineforminputs"
              cols="30"
              rows="3"
            />
            <button
             onClick={handleSubmit}
             type="submit"
             className="contactbtn"
             style={{ padding: '1.5rem 2rem' }}
             disabled={isSubmitting} // Disable the button while submitting
             aria-label={isSubmitting ? 'Submitting Message' : 'Send Message'}
           >
             {isSubmitting ? (
               <p className="headerbtncon">Submitting...</p>
             ) : (
               <>
                 <p className="headerbtncon">Send Message</p>
                 <FaArrowRightLong
                   className="headerbtnarrow"
                   style={{ fontSize: '1.5rem' }}
                 />
               </>
             )}
           </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroMachine;
