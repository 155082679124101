import React from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { SiAdobeacrobatreader } from 'react-icons/si'
import { Link } from 'react-router-dom'
import MachineSpecs from '../../Components/MachineComponent/MachineSpecs'
import specs from '../../Data/UAEPaperBag.json'
import RelatedProducts from '../../Components/MachineComponent/RelatedProducts'
import Form1 from '../../Components/MarketArea/Form1'
import Form2 from '../../Components/MarketArea/Form2'
import { Helmet } from 'react-helmet'
const UAEPaperBag = (visitData) => {
    return (
        <>
        <Helmet>
        <title>UAE | Nessco ماكينة تصنيع الاكياس الورقية</title>
<meta name="description" content="نيسكو تقدم مجموعة كاملة من ماكينة تصنيع الاكياس الورقية في الإمارات العربية المتحدة بأحدث التقنيات منذ 40 عامًا. سرعة عالية، استهلاك طاقة منخفض. احصل على عرض أسعار الآن!" />
<meta name="keywords" content=""/>
<meta name="copyright" content="Copyright 2022 Nessco India" /> 
<meta name="distribution" content="global" />
<meta name="language" content="english" /> 
<meta name="robots" content="index,follow" /> 
<meta name="publisher" content="Nessco" />
<meta name="author" content="Nessco" />
<meta property="og:image" content="https://www.nesscoindia.com/Assets/images/service/Fully-Automatic-Paper-Bag-Machine.webp" />
<link rel="canonical" href="https://www.nesscoindia.com/uae/ماكينة-تصنيع-الاكياس-الورقية/"/>
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-16529601205"></script>

<script src="https://cdn.pagesense.io/js/nesscoindia/726a5bfbad6c4f5091eda44cd7ec1cb1.js"></script>
        </Helmet>
            <section className="intromachine">
                <div className="intromachinecompo">
                    <div className="intromachineleft">
                        {/* <div className="breadcrumconnections">
                        <Link to={'/'} className='breadcrumconnectiondislink'>Home</Link> <FaAngleRight className='breadcrumicon2'/><Link to={'/product'} className='breadcrumconnectiondislink'>Products</Link><FaAngleRight className='breadcrumicon2' /><p className='breadcrumconnectionactivelink'>{props.heading}</p>
                    </div> */}
                        <h1 className="intromachineheading">تصنيع الاكياس الورقية<br /><span>ماكينة</span></h1>
                        <p className="intromachinedescription">
                        شركة نيسكو بالهند هى وجهتك الاولي لماكينات صناعة الأكياس الورقيه.
معروفه عالميا لتصنيع هذه الأكياس علي مدار ٤٠ سنه من الخبره.
قمنا بدمج تكنولوجيا التقطيع مع القوي العامله النشطه لبناء حلول تغليف مستدامه.
الماكينات لدينا تقوم بإنتاج عدد كبير من الأكياس مثل أكياس التسوق ،أكياس البقاله،أكياس المخبوزات و الأكياس الصغيره
لتلبية احتياجاتك المختلفة للتغليف.
                        </p>
                    </div>
                    <Form1 visitData={visitData}/>
                </div>
            </section>
            <section className="descriptionmachine">
                <div className="descriptionmachinecomp">
                    <div className="descriptionmachineleft">
                        <img src='https://www.nesscoindia.com/Assets/images/service/Fully-Automatic-Paper-Bag-Machine.webp' alt="" />
                        <h3 className="descriptionmachineleftdata">صنع الأكياس الورقيه<br /><span>ماكينة</span></h3>
                        <p className="descriptionmachineleftcontent">
                            ماكينة تصنيع الأكياس الورقيه فى نيسكو ذات السرعه الفائقه تم تصميمها بتكنولوجيا حديثه نتج عنها تحسين الانتاج ،
الاستقرار و جودة المنتج.
جميع الأجزاء و قطع الغيار المستخدمة تضمن مرونة الإنتاج و صيانة للمعدات.
الماكينه قادره علي العمل بكفاءة ، ثبات و سهولة في التشغيل.
حيث انها تقوم بانتاج أشكال متعدده من الأكياس الورقيه مثل أكياس التسوق ، أكياس الهدايا و أكياس الطعام و غيرها الكثير.
                        </p>
                        <h3 className="descriptionmachineleftdata">تعريف المنتجات<br /><span></span></h3>
                        <p className="descriptionmachineleftcontent">
                        تقدم نيسكو آلة تصنيع الأكياس الورقية الأوتوماتيكية بالكامل
مم 1500x6000x2700 بأبعاد
القادرة على إنتاج ما بين 30 إلى 200 كيس في الدقيقة. تتميز هذه الآلة بمصدر طاقة ثلاثي المراحل 380 فولت،
مما يضمن تشغيلًا فعالًا وموثوقًا. هذه الآلة مزودة بحساسات ضوئية ألمانية، مما يمكّنها من تتبع حركة الورق بدقة طوال
عملية التصنيع، سواء كان ذلك في محاذاة ، فك الورق وإعادة اللف؟
تأتي هذه الآلة مع مجموعة من الوظائف بما في ذلك تتبع الطباعة، القطع، تشكيل ، طي ، ولصق قاع الاكياس، مما يجعل
عملية الإنتاج سلسة وسهلة.
                        </p>
                    </div>
                    <div className="descriptionmachineright">
                        <div className="descriptionsuggestions">
                        {
                                specs.Suggestions.map((obj,key) => (
                                    <Link key={key} to={obj.suggestionlink} className="descriptionsuggestion">
                                        <FaAngleDoubleRight />{obj.name}
                                    </Link>

                                ))
                            }
                        </div>
                        <Form2 visitData={visitData}/>
                        <div className="downloadbrochures">
                            <h3 className="downloadbrochuresheading">Download Brochures</h3>
                            <p className="downloadbrochuressubheading">Download Our Catalogue to know more about machines</p>
                            <div className="brochurecontent">
                                <div className="brochurelogo">
                                    <SiAdobeacrobatreader />
                                </div>
                                <div className="brochuredescription">
                                    <a href='/' className="brochuretitle">Nessco India Catalogue</a>
                                    <a href='/' className="brochuresubtitle">Download Now</a>
                                </div>
                            </div>
                            <div className="brochurecontent">
                                <div className="brochurelogo">
                                    <SiAdobeacrobatreader />
                                </div>
                                <div className="brochuredescription">
                                    <a href='/' className="brochuretitle">International Catalogue</a>
                                    <a href='/' className="brochuresubtitle">Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MachineSpecs labels={specs.MachineSpecsLabels} data={specs.MachineSpecs}/>
            <RelatedProducts data={specs.RelatedProducts}/>
        </>
    )
}

export default UAEPaperBag
