import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countryCodes from '../../Data/phonenocode.json';

const MachineSpecs3 = ({ visitData, ...props }) => {
  const data = props.data;

  const [isOpen2, setIsOpen2] = useState(false);
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => setIsOpen2(false);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    phoneNumber: '',
    email: '',
  });

  const [formData, setFormData] = useState({
    Last_Name: '',
    Email: '',
    Web_Message: '',
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dropdownRef = useRef(null); // Ref for dropdown container

  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);

  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  const validateAndFormatPhone = (fullNumber, iso2) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, iso2);
      return phoneObj && phoneObj.isValid() ? phoneObj.format('E.164') : null;
    } catch (err) {
      console.error('Error validating phone number:', err);
      return null;
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: '', email: '' };

    const fullNumber = `${selectedCountry?.dial_code || ''}${localPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, selectedCountry?.iso2);

    if (!localPhone) {
      newErrors.phoneNumber = 'Phone number is required';
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    if (!formData.Email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = 'Email is not valid';
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        const payload = {
          ...formData,
          Mobile: formattedPhone,
          visitData,
        };
  
        const response = await axios.post(
          'https://website-backend-eosin.vercel.app/form-submission',
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'formSubmitted' });

        window.pagesense = window.pagesense || [];
        window.pagesense.push(['trackEvent', 'formSubmitted']);

          navigate('/thank-you/');
        props.setdown?.(true);
        closeModal2();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!selectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <>
      <section className="machinespecs3">
        <div className="machinespecs3container">
          <h3 className="machinespecs3heading">{props.Heading}</h3>
          <div className="machinespecsss">
            <div className="machinespecsleft">
              <img src={props.imglink} alt={props.Heading} />
            </div>
            <div className="machinespecsright">
              <table className="machinetable">
                <thead className="machinehead">
                  <tr>
                    <th style={cellStyle}>FEATURE</th>
                    <th style={cellStyle}>SPECIFICATION</th>
                  </tr>
                </thead>
                <tbody className="machinedata">
                  {data.map((row, key) => (
                    <tr key={key} className="machinetablerow">
                      <td style={cellStyle}>{row.feature}</td>
                      <td style={cellStyle}>{row.specs}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={openModal2} className="headerbtn h">
                <p className="headerbtncon">Inquire Now !</p>
                <FaArrowRightLong className="headerbtnarrow" style={{ fontSize: '1.5rem' }} />
              </button>
              {isOpen2 && (
                <div className="modal">
                  <div className="modalcard">
                    <button className="close-button" onClick={closeModal2}>
                      &times;
                    </button>
                    <div className="leftmodal">
                      <img src="https://www.nesscoindia.com/Assets/images/resource/popup.webp" alt="Popup" />
                    </div>
                    <div className="rightmodal">
                      <h1 className="modalheading">Request for details to receive a call back</h1>
                      <p className="modaldesc">Enter your details to receive a call back</p>
                      <input
                        value={formData.Last_Name}
                        onChange={handleChange}
                        name="Last_Name"
                        placeholder="Enter your Name*"
                        className="modalinp"
                        type="text"
                      />
                      <input
                        value={formData.Email}
                        onChange={handleChange}
                        name="Email"
                        placeholder="Email"
                        className="modalinp"
                        type="email"
                      />
                      {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      <div ref={dropdownRef} onClick={() => setShowDropdown(!showDropdown)} className="modalinp">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            position: 'relative',
                          }}
                        //   onClick={toggleDropdown}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                            <img
                              src={selectedCountry.flagUrl}
                              alt={`${selectedCountry.iso2} flag`}
                              style={{ width: '20px', height: '14px', objectFit: 'cover' }}
                            />
                            <span>{selectedCountry.iso2}</span>
                            <span>({selectedCountry.dial_code})</span>
                          </div>
                          <span>&#x25BC;</span>
                          {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
                        </div>
                        <input
                          value={localPhone}
                          onChange={handlePhoneChange}
                          placeholder="Phone Number*"
                          className="modalinp"
                          type="text"
                        />
                        {errors.phoneNumber && <p style={{ color: 'red' }}>{errors.phoneNumber}</p>}
                      </div>
                      <button
      onClick={handleSubmit}
      className="headerbtn x"
      style={{ padding: "2rem 3rem" }}
      disabled={isSubmitting} // Disable the button while submitting
      aria-label={isSubmitting ? 'Submitting Quote Request' : 'Get a Quote'}
    >
      {isSubmitting ? (
        <p className="headerbtncon">Submitting...</p>
      ) : (
        <>
          <p className="headerbtncon">Get a Quote!</p>
          <FaArrowRightLong
            className="headerbtnarrow"
            style={{ fontSize: '1.5rem' }}
          />
        </>
      )}
    </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const cellStyle = {
  border: '1px solid grey',
  padding: '8px',
  fontSize: '1.4rem',
  textAlign: 'start',
};

export default MachineSpecs3;
