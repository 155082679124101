import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import countryCodes from "../../Data/phonenocode.json";

const Form2 = ({ visitData }) => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: ""
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dropdownRef = useRef(null); // Ref for dropdown container

  // Set default country based on visitData or fallback to "IN"
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.visitData?.Country_ISO2) {
      const iso2 = visitData.visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateAndFormatPhone = (fullNumber, iso2) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, iso2);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (err) {
      console.error("Error validating phone number:", err);
      return null;
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, selectedCountry?.iso2);

    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);

      try {
        const payload = {
          ...formData,
          Mobile: formattedPhone,
          ...visitData
        };

        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });

        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!selectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <div className="descriptionaboutaquote">
      <h3 className="descriptionaboutaquoteheading">Get a Quote</h3>
      <input
        value={formData.Last_Name}
        onChange={handleChange}
        name="Last_Name"
        placeholder="Full Name*"
        className="descriptionaboutaquoteinputs"
        type="text"
        required
      />
      <div className="validations" style={{ width: "100%" }}>
        <input
          value={formData.Email}
          onChange={handleChange}
          name="Email"
          placeholder="Email"
          className="descriptionaboutaquoteinputs"
          type="text"
        />
        {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
      </div>
      <div
        className="validations"
        style={{ width: "100%", display: "flex", gap: "0.5rem" }}
      >
        <div
          className="descriptionaboutaquoteinputs"
          ref={dropdownRef}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            cursor: "pointer",
            flex: "0 0 40%"
          }}
        //   onClick={toggleDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
        >
          {selectedCountry && (
            <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
              <img
                src={selectedCountry.flagUrl}
                alt={`${selectedCountry.iso2} flag`}
                style={{ width: "20px", height: "14px", objectFit: "cover" }}
              />
              <span>{selectedCountry.iso2}</span>
              <span>({selectedCountry.dial_code})</span>
            </div>
          )}
          <span>&#x25BC;</span>
          {showDropdown && (
            <ul
              style={{
                position: "absolute",
                listStyle: "none",
                margin: 0,
                padding: "0.5rem",
                background: "#fff",
                border: "1px solid #ccc",
                width: "100%",
                maxHeight: "150px",
                overflowY: "auto",
                top: "100%",
                left: 0,
                zIndex: 9999
              }}
            >
              {countryCodes.map((c, idx) => (
                <li
                  key={idx}
                  onClick={() => handleSelectCountry(c)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                    padding: "4px 0",
                    cursor: "pointer"
                  }}
                >
                  <img
                    src={c.flagUrl}
                    alt={`${c.iso2} flag`}
                    style={{ width: "20px", height: "14px", objectFit: "cover" }}
                  />
                  <span>{c.iso2}</span>
                  <span>({c.dial_code})</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <input
            value={localPhone}
            onChange={handlePhoneChange}
            name="Mobile"
            placeholder="Phone Number*"
            required
            className="descriptionaboutaquoteinputs"
            type="text"
          />
        </div>
      </div>
      {errors.phoneNumber && <p style={{ color: "red" }}>{errors.phoneNumber}</p>}
      <textarea
        value={formData.Web_Message}
        onChange={handleChange}
        name="Web_Message"
        placeholder="Message"
        className="descriptionaboutaquoteinputs"
        cols="30"
        rows="3"
      ></textarea>
       <button
        onClick={handleSubmit}
        type="submit"
        className="contactbtn"
        style={{ padding: '1.5rem 2rem' }}
        disabled={isSubmitting} // Disable button during submission
        aria-label={isSubmitting ? 'Submitting Message' : 'Send Message'}
      >
        {isSubmitting ? (
          <p className="headerbtncon">Submitting...</p>
        ) : (
          <>
            <p className="headerbtncon">Send Message</p>
            <FaArrowRightLong
              className="headerbtnarrow"
              style={{ fontSize: '1.5rem' }}
            />
          </>
        )}
      </button>
    </div>
  );
};

export default Form2;
