import React, { useState, useEffect, useRef } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { SiAdobeacrobatreader } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// 1) Import parsePhoneNumberFromString
import { parsePhoneNumberFromString } from "libphonenumber-js";

// 2) Import your country codes data
import countryCodes from "../../Data/phonenocode.json"; // Adjust path if needed

const DescriptionMachine = ({ visitData, ...props }) => {
  const data = props.data;
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Main "Get a Quote"
  const localDropdownRef = useRef(null); // Nessco India Catalogue
  const intDropdownRef = useRef(null); // International Catalogue

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close main dropdown
      }
      if (localDropdownRef.current && !localDropdownRef.current.contains(event.target)) {
        setLocalShowDropdown(false); // Close Nessco dropdown
      }
      if (intDropdownRef.current && !intDropdownRef.current.contains(event.target)) {
        setIntShowDropdown(false); // Close International dropdown
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // =========================
  //  State for "Get a Quote" form
  // =========================
  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: "",
  });

  // For phone in the main "Get a Quote" section
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // =========================
  //  State for "Nessco India Catalogue" modal
  // =========================
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // This modal's phone (Nessco India)
  const [downloadLocalName, setDownloadLocalName] = useState("");
  const [downloadLocalEmail, setDownloadLocalEmail] = useState("");
  const [localSelectedCountry, setLocalSelectedCountry] = useState(null);
  const [localLocalPhone, setLocalLocalPhone] = useState("");
  const [localShowDropdown, setLocalShowDropdown] = useState(false);
  const [downloadLocalErrors, setDownloadLocalErrors] = useState({
    phoneNumber: "",
    email: "",
  });

  // =========================
  //  State for "International Catalogue" modal
  // =========================
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => setIsOpen2(false);

  // This modal's phone (International)
  const [downloadIntName, setDownloadIntName] = useState("");
  const [downloadIntEmail, setDownloadIntEmail] = useState("");
  const [intSelectedCountry, setIntSelectedCountry] = useState(null);
  const [intLocalPhone, setIntLocalPhone] = useState("");
  const [intShowDropdown, setIntShowDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [downloadIntErrors, setDownloadIntErrors] = useState({
    phoneNumber: "",
    email: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);

  // =========================
  //  On mount => pick default country from visitData or fallback "IN"
  // =========================
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];

    // For the main "Get a Quote" form
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);

    // For Nessco India modal
    setLocalSelectedCountry(fallback);

    // For International modal
    setIntSelectedCountry(fallback);
  }, [visitData]);
  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // =========================
  //  Basic validators
  // =========================
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateAndFormatPhone = (fullNumber, iso2) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, iso2);
      return phoneObj && phoneObj.isValid() ? phoneObj.format("E.164") : null;
    } catch (err) {
      console.error("Error validating phone number:", err);
      return null;
    }
  };

  // =========================
  //  For text "Contact us today" => link
  // =========================
  const renderImageDescription = (text) => {
    const targetText = "Contact us today";
    if (text.includes(targetText)) {
      const [beforeText, afterText] = text.split(targetText);
      return (
        <>
          {beforeText}
          <Link to="/contact/" style={{ color: "#483D73", fontWeight: "600" }}>
            {targetText}
          </Link>
          {afterText}
        </>
      );
    }
    return text;
  };

  // =========================
  //  Main "Get a Quote" form changes
  // =========================
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };
  const toggleDropdownFn = () => setShowDropdown((prev) => !prev);
  const handleSelectCountryFn = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  // Submit => main "Get a Quote"
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    // Combine => parse
    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, selectedCountry?.iso2);

    // Phone checks
    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }
    // Email checks
    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }
    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        const payload = {
          ...formData,
          Mobile: formattedPhone,
          visitData,
        };

        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        // GTM + Zoho
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  // =========================
  //  Download "Nessco India Catalogue" modal
  // =========================
  const toggleDownloadLocalDropdown = () => setLocalShowDropdown((prev) => !prev);
  const handleSelectDownloadLocalCountry = (country) => {
    setLocalSelectedCountry(country);
    setLocalShowDropdown(false);
  };
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close the dropdown after selection
  };

  const handleSubmitDownloadLocal = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErr = { phoneNumber: "", email: "" };

    // Combine => parse
    const fullNumber = `${localSelectedCountry?.dial_code || ""}${localLocalPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, localSelectedCountry?.iso2);

    // Phone checks
    if (!localLocalPhone) {
      newErr.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErr.phoneNumber = `Invalid phone number for ${localSelectedCountry?.iso2}`;
      valid = false;
    }
    // Email checks
    if (!downloadLocalEmail) {
      newErr.email = "Email is required";
      valid = false;
    } else if (!validateEmail(downloadLocalEmail)) {
      newErr.email = "Email is not valid";
      valid = false;
    }
    setDownloadLocalErrors(newErr);

    if (valid) {
      setIsSubmitting(true);
      try {
        // Build payload => E.164 phone => "Mobile"
        const payload = {
          Last_Name: downloadLocalName,
          Email: downloadLocalEmail,
          Mobile: formattedPhone,
          // Optionally also add visitData
          visitData,
        };

        // Send or do your logic. For example:
        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        // GTM + Zoho
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
        closeModal();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  // =========================
  //  Download "International Catalogue" modal
  // =========================
  const toggleDownloadIntDropdown = () => setIntShowDropdown((prev) => !prev);
  const handleSelectDownloadIntCountry = (country) => {
    setIntSelectedCountry(country);
    setIntShowDropdown(false);
  };

  const handleSubmitDownloadInt = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErr = { phoneNumber: "", email: "" };

    // Combine => parse
    const fullNumber = `${intSelectedCountry?.dial_code || ""}${intLocalPhone}`;
    const formattedPhone = validateAndFormatPhone(fullNumber, intSelectedCountry?.iso2);

    // Phone checks
    if (!intLocalPhone) {
      newErr.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErr.phoneNumber = `Invalid phone number for ${intSelectedCountry?.iso2}`;
      valid = false;
    }
    // Email checks
    if (!downloadIntEmail) {
      newErr.email = "Email is required";
      valid = false;
    } else if (!validateEmail(downloadIntEmail)) {
      newErr.email = "Email is not valid";
      valid = false;
    }
    setDownloadIntErrors(newErr);

    if (valid) {
      setIsSubmitting(true);
      try {
        // Build payload => E.164 phone => "Mobile"
        const payload = {
          Last_Name: downloadIntName,
          Email: downloadIntEmail,
          Mobile: formattedPhone,
          // Optionally also add visitData
          visitData,
        };

        // Send or do your logic. For example:
        const response = await axios.post(
          "https://website-backend-eosin.vercel.app/form-submission",
          payload,
          { headers: { "Content-Type": "application/json" } }
        );

        // GTM + Zoho
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "formSubmitted" });
        window.pagesense = window.pagesense || [];
        window.pagesense.push(["trackEvent", "formSubmitted"]);

          navigate("/thank-you/");
        closeModal2();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  // If we haven't selected default countries yet
  if (!selectedCountry || !localSelectedCountry || !intSelectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <section className="descriptionmachine">
      <div className="descriptionmachinecomp">
        {/* Left side content */}
        <div className="descriptionmachineleft">
          <img
            src={props.imgurl}
            alt={`${data.PointsHeading[0]} ${data.PointsHeading[1]}`}
          />
          {data.Imgdesc.map((obj, key) => (
            <p key={key} className="descriptionmachineleftcontent">
              {obj}
            </p>
          ))}
          {data.PointsComponent && (
            <>
              <h2 className="descriptionmachineleftdata">
                {data.PointsHeading[0]} <span>{data.PointsHeading[1]}</span>
              </h2>
              <p className="descriptionmachineleftcontent">
                {data.PointsHeadingDesc}
              </p>
              {!data.NotPoints && (
                <div className="descriptionmachineleftcontentlist">
                  <li className="descriptionpoints">{data.PointsDescription[0]}</li>
                  <li className="descriptionpoints">{data.PointsDescription[1]}</li>
                  <li className="descriptionpoints">{data.PointsDescription[2]}</li>
                  <li className="descriptionpoints">{data.PointsDescription[3]}</li>
                </div>
              )}
            </>
          )}
          {data.ImageComponent && (
            <div className="descriptionmachineleftimage">
              <div className="descriptionmachineimageleft">
                <h2 className="descriptionmachineleftdata">
                  {data.ImageHeading[0]} <span>{data.ImageHeading[1]}</span>
                </h2>
                <p className="descriptionmachineleftcontent">
                  {renderImageDescription(data.ImageDescription)}
                </p>
              </div>
              <div className="descriptionmachineimageright">
                <img src={props.img2url} alt="" />
              </div>
            </div>
          )}
          {data.MultipleImageComp && (
            <div className="descriptionmachineleftimage">
              <div className="descriptionmachineimageleft">
                <h2 className="descriptionmachineleft">
                  {data.MultipleImageHeading}
                </h2>
                <p className="descriptionmachineleftcontent">
                  {data.MultipleImageDescription}
                </p>
              </div>
              <div className="descriptionmachineimageright">
                <img src={props.img3url} alt="" />
              </div>
            </div>
          )}
        </div>

        {/* Right side => suggestions & main "Get a Quote" */}
        <div className="descriptionmachineright">
          <div className="descriptionsuggestions">
            {data.Suggestions.map((obj, key) => (
              <Link
                key={key}
                to={obj.suggestionlink}
                className="descriptionsuggestion"
              >
                <FaAngleDoubleRight />
                {obj.name}
              </Link>
            ))}
          </div>

          {/* "Get a Quote" => country code approach */}
          <div className="descriptionaboutaquote">
            <h3 className="descriptionaboutaquoteheading">Get a Quote</h3>
            {/* Name */}
            <input
              style={{ background: "white" }}
              value={formData.Last_Name}
              onChange={handleChange}
              name="Last_Name"
              placeholder="Full Name*"
              className="presenceinpfields"
              type="text"
              required
            />
            {/* Email */}
            <div className="validations" style={{ width: "100%" }}>
              <input
                style={{ background: "white" }}
                value={formData.Email}
                onChange={handleChange}
                name="Email"
                placeholder="Email"
                className="presenceinpfields"
                type="text"
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </div>
            {/* Dropdown + local phone */}
            <div
              style={{
                display: "flex",
                gap: "8px",
                width: "100%",
                marginBottom: "1rem",
              }}
            >
              {/* Country => .presenceinpfields style */}
              <div
                ref={dropdownRef}
                className="presenceinpfields"
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  cursor: "pointer",
                  flex: "0 0 40%",
                }}
                // onClick={toggleDropdownFn}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
                  <img
                    src={selectedCountry.flagUrl}
                    alt={`${selectedCountry.iso2} flag`}
                    style={{ width: "20px", height: "14px", objectFit: "cover" }}
                  />
                  <span>{selectedCountry.iso2}</span>
                  <span>({selectedCountry.dial_code})</span>
                </div>
                <span>&#x25BC;</span>
                {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
              </div>
              {/* Local phone => .presenceinpfields */}
              <div style={{ flex: 1 }}>
                <input
                  style={{ background: "white" }}
                  value={localPhone}
                  onChange={handlePhoneChange}
                  placeholder="Phone Number*"
                  required
                  className="presenceinpfields"
                  type="text"
                />
              </div>
            </div>
            {errors.phoneNumber && (
                  <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                )}
            {/* Message */}
            <textarea
              style={{ background: "white" }}
              value={formData.Web_Message}
              onChange={handleChange}
              name="Web_Message"
              placeholder="Message"
              className="presenceinpfields"
              cols="30"
              rows="3"
            />
            {/* Submit */}
            {isSubmitting ? (
  <button
    onClick={handleSubmit}
    type="submit"
    className="contactbtn"
    style={{ padding: "1.5rem 2rem" }}
    disabled
  >
    <p className="headerbtncon">Submitting...</p>
  </button>
) : (
  <button
    onClick={handleSubmit}
    type="submit"
    className="contactbtn"
    style={{ padding: "1.5rem 2rem" }}
  >
    <p className="headerbtncon">Send Message</p>
    <FaArrowRightLong
      className="headerbtnarrow"
      style={{ fontSize: "1.5rem" }}
    />
  </button>
)}
          </div>

          {/* "Download Brochures" => 2 modals */}
          <div className="downloadbrochures">
            <h4 className="downloadbrochuresheading">Download Brochures</h4>
            <p className="downloadbrochuressubheading">
              Download Our Catalogue to know more about machines
            </p>

            {/* == 1) Nessco India Catalogue == */}
            <div className="brochurecontent">
              <div className="brochurelogo">
                <SiAdobeacrobatreader />
              </div>
              <div className="brochuredescription">
                <button onClick={openModal} className="brochuresubtitle">
                  Nessco India Catalogue
                </button>
                <button className="brochuresubtitle" onClick={openModal}>
                  Download Now
                </button>

                {isOpen && (
                  <div className="modal">
                    <div className="modalcard">
                      <button className="close-button" onClick={closeModal}>
                        &times;
                      </button>
                      <div className="leftmodal">
                        <img
                          src={"https://www.nesscoindia.com/Assets/images/resource/popup.webp"}
                          alt="POPUP"
                        />
                      </div>
                      <div className="rightmodal">
                        <h1 className="modalheading">
                          Request for details to receive a call back
                        </h1>
                        <p className="modaldesc">
                          Enter your details to receive a call back
                        </p>
                        {/* Name */}
                        <input
                          placeholder="Enter your Name"
                          className="modalinp"
                          type="text"
                          value={downloadLocalName}
                          onChange={(e) => setDownloadLocalName(e.target.value)}
                        />
                        {/* Email */}
                        <input
                          placeholder="Enter your Email"
                          className="modalinp"
                          type="text"
                          value={downloadLocalEmail}
                          onChange={(e) => setDownloadLocalEmail(e.target.value)}
                        />
                        {downloadLocalErrors.email && (
                          <p style={{ color: "red" }}>{downloadLocalErrors.email}</p>
                        )}
                        {/* Country dropdown + local phone => same approach */}
                        <div
                          style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem", width: "100%" }}
                        >
                          <div
                            className="modalinp"
                            ref={localDropdownRef}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              position: "relative",
                              cursor: "pointer",
                              flex: "0 0 40%",
                            }}
                            onClick={toggleDownloadLocalDropdown}
                          >
                            {localSelectedCountry && (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.4rem",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={localSelectedCountry.flagUrl}
                                  alt={`${localSelectedCountry.iso2} flag`}
                                  style={{
                                    width: "20px",
                                    height: "14px",
                                    objectFit: "cover",
                                  }}
                                />
                                <span>{localSelectedCountry.iso2}</span>
                                <span>({localSelectedCountry.dial_code})</span>
                              </div>
                            )}
                            <span>&#x25BC;</span>
                            {localShowDropdown && (
                              <ul
                                style={{
                                  position: "absolute",
                                  listStyle: "none",
                                  margin: 0,
                                  padding: "0.5rem",
                                  background: "#fff",
                                  border: "1px solid #ccc",
                                  width: "100%",
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                  top: "100%",
                                  left: 0,
                                  zIndex: 9999,
                                }}
                              >
                                {countryCodes.map((c, idx) => (
                                  <li
                                    key={idx}
                                    onClick={() => handleSelectDownloadLocalCountry(c)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0.4rem",
                                      padding: "4px 0",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={c.flagUrl}
                                      alt={`${c.iso2} flag`}
                                      style={{
                                        width: "20px",
                                        height: "14px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <span>{c.iso2}</span>
                                    <span>({c.dial_code})</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          {/* local phone for local catalog */}
                          <div style={{ flex: 1 }}>
                            <input
                              placeholder="Enter your Phone"
                              className="modalinp"
                              type="text"
                              value={localLocalPhone}
                              onChange={(e) => setLocalLocalPhone(e.target.value)}
                            />
                            
                          </div>
                        </div>
                        {downloadLocalErrors.phoneNumber && (
                              <p style={{ color: "red" }}>{downloadLocalErrors.phoneNumber}</p>
                            )}

                        {/* Submit => handleSubmitDownloadLocal */}
                        {isSubmitting ? (
  <button
    className="headerbtn x"
    style={{ padding: "2rem 3rem" }}
    disabled
  >
    <p className="headerbtncon">Submitting...</p>
  </button>
) : (
  <button
    onClick={handleSubmitDownloadLocal}
    className="headerbtn x"
    style={{ padding: "2rem 3rem" }}
  >
    <p className="headerbtncon">Get a Quote !</p>
    <FaArrowRightLong
      className="headerbtnarrow"
      style={{ fontSize: "1.5rem" }}
    />
  </button>
)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* == 2) International Catalogue == */}
            <div className="brochurecontent">
              <div className="brochurelogo">
                <SiAdobeacrobatreader />
              </div>
              <div className="brochuredescription">
                <button onClick={openModal2} className="brochuresubtitle">
                  International Catalogue
                </button>
                <button className="brochuresubtitle" onClick={openModal2}>
                  Download Now
                </button>
                {isOpen2 && (
                  <div className="modal">
                    <div className="modalcard">
                      <button className="close-button" onClick={closeModal2}>
                        &times;
                      </button>
                      <div className="leftmodal">
                        <img
                          src={"https://www.nesscoindia.com/Assets/images/resource/popup.webp"}
                          alt="Popup"
                        />
                      </div>
                      <div className="rightmodal">
                        <h1 className="modalheading">
                          Request for details to receive a call back
                        </h1>
                        <p className="modaldesc">
                          Enter your details to receive a call back
                        </p>
                        {/* Name */}
                        <input
                          placeholder="Enter your Name"
                          className="modalinp"
                          type="text"
                          value={downloadIntName}
                          onChange={(e) => setDownloadIntName(e.target.value)}
                        />
                        {/* Email */}
                        <input
                          placeholder="Enter your Email"
                          className="modalinp"
                          type="text"
                          value={downloadIntEmail}
                          onChange={(e) => setDownloadIntEmail(e.target.value)}
                        />
                        {downloadIntErrors.email && (
                          <p style={{ color: "red" }}>{downloadIntErrors.email}</p>
                        )}

                        {/* country code + local phone => second modal */}
                        <div
                          style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem", width: "100%" }}
                        >
                          <div
                            className="modalinp"
                            ref={intDropdownRef}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              position: "relative",
                              cursor: "pointer",
                              flex: "0 0 40%",
                            }}
                            onClick={toggleDownloadIntDropdown}
                          >
                            {intSelectedCountry && (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.4rem",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={intSelectedCountry.flagUrl}
                                  alt={`${intSelectedCountry.iso2} flag`}
                                  style={{
                                    width: "20px",
                                    height: "14px",
                                    objectFit: "cover",
                                  }}
                                />
                                <span>{intSelectedCountry.iso2}</span>
                                <span>({intSelectedCountry.dial_code})</span>
                              </div>
                            )}
                            <span>&#x25BC;</span>
                            {intShowDropdown && (
                              <ul
                                style={{
                                  position: "absolute",
                                  listStyle: "none",
                                  margin: 0,
                                  padding: "0.5rem",
                                  background: "#fff",
                                  border: "1px solid #ccc",
                                  width: "100%",
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                  top: "100%",
                                  left: 0,
                                  zIndex: 9999,
                                }}
                              >
                                {countryCodes.map((c, idx) => (
                                  <li
                                    key={idx}
                                    onClick={() => handleSelectDownloadIntCountry(c)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0.4rem",
                                      padding: "4px 0",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={c.flagUrl}
                                      alt={`${c.iso2} flag`}
                                      style={{
                                        width: "20px",
                                        height: "14px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <span>{c.iso2}</span>
                                    <span>({c.dial_code})</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>

                          {/* local phone => second modal */}
                          <div style={{ flex: 1 }}>
                            <input
                              placeholder="Enter your Phone"
                              className="modalinp"
                              type="text"
                              value={intLocalPhone}
                              onChange={(e) => setIntLocalPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        {downloadIntErrors.phoneNumber && (
                              <p style={{ color: "red" }}>
                                {downloadIntErrors.phoneNumber}
                              </p>
                            )}

                        {/* Submit => handleSubmitDownloadInt */}
                        {isSubmitting ? (
      <button
        className="headerbtn x"
        style={{ padding: "2rem 3rem" }}
        disabled
        aria-label="Submitting Quote Request"
      >
        <p className="headerbtncon">Submitting...</p>
      </button>
    ) : (
      <button
        onClick={handleSubmitDownloadInt}
        className="headerbtn x"
        style={{ padding: "2rem 3rem" }}
        aria-label="Get a Quote"
      >
        <p className="headerbtncon">Get a Quote!</p>
        <FaArrowRightLong
          className="headerbtnarrow"
          style={{ fontSize: "1.5rem" }}
        />
      </button>
    )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* End of the 2 modals */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DescriptionMachine;
