import React, { useState, useEffect, useRef } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// 1) Import or define your country codes data
import countryCodes from '../../Data/phonenocode.json';
// (Ensure this file has a JSON array of { name, iso2, dial_code, flagUrl })

const Presence = ({ visitData }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for dropdown

  // Track errors => { phoneNumber: "", email: "" }
  const [errors, setErrors] = useState({ phoneNumber: "", email: "" });

  // Basic form fields (excluding phone logic)
  const [formData, setFormData] = useState({
    Last_Name: "",
    Email: "",
    Web_Message: "",
  });

  // 2) For country + local phone
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [localPhone, setLocalPhone] = useState("");

  // Show/hide custom dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);
  const [isSubmitting, setIsSubmitting] = useState(false);  

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close dropdown
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // On mount, figure out default from visitData or fallback to "IN"
  useEffect(() => {
    let fallback = countryCodes.find((c) => c.iso2 === "IN") || countryCodes[0];
    if (visitData?.Country_ISO2) {
      const iso2 = visitData.Country_ISO2;
      const found = countryCodes.find((c) => c.iso2 === iso2);
      if (found) fallback = found;
    }
    setSelectedCountry(fallback);
  }, [visitData]);

  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // For the name, email, subject, message
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // For the local phone portion
  const handlePhoneChange = (e) => {
    setLocalPhone(e.target.value);
  };

  // Toggle the dropdown
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Select a country => auto-close
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  // Basic email validation
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Use libphonenumber-js to parse & validate phone
  const validateAndFormatPhoneNumber = (fullNumber, countryIso) => {
    try {
      const phoneObj = parsePhoneNumberFromString(fullNumber, countryIso);
      return phoneObj && phoneObj.isValid() ? phoneObj.format('E.164') : null;
    } catch (err) {
      console.error('Error validating phone number:', err);
      return null;
    }
  };

  // On submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { phoneNumber: "", email: "" };

    // Combine dial_code + local phone => parse
    const fullNumber = `${selectedCountry?.dial_code || ""}${localPhone}`;
    const formattedPhone = validateAndFormatPhoneNumber(fullNumber, selectedCountry?.iso2);

    // Validate phone
    if (!localPhone) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    } else if (!formattedPhone) {
      newErrors.phoneNumber = `Invalid phone number for ${selectedCountry?.iso2}`;
      valid = false;
    }

    // Validate email
    if (!formData.Email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setIsSubmitting(true);
      try {
        // Build the final payload => store E.164 phone as "Mobile"
        let visitedData;
        if (visitData && visitData.Country_ISO2) {
          visitedData = { ...visitData };
        } else {
          // If there's a nested visitData, fallback here
          visitedData = { ...visitData?.visitData };
        }

        const payload = {
          ...formData,
          Mobile: formattedPhone,
          visitData: visitedData, // Include visitData in the payload
        };
 
        const response = await axios.post(
          'https://website-backend-eosin.vercel.app/form-submission',
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        // GTM event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'formSubmitted' });

        // Zoho PageSense event
        window.pagesense = window.pagesense || [];
        window.pagesense.push(['trackEvent', 'formSubmitted']);

        navigate('/thank-you/');
        
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!selectedCountry) {
    return <div>Loading phone data...</div>;
  }

  return (
    <section className="presenceSection">
      <div className="presenceContainer">
        <div className="staticimages">
          <div className="staticimage1" />
          <div className="staticimage2" />
        </div>

        <div className="leftpresence">
          <p className="aboutusbtn">PRESENCE</p>
          <h2 className="presenceheading">
            Nessco Global <span> Presence</span>
          </h2>
          <p className="presencepara">
            With a strong functional position in this market for over 40 years, 
            now Nessco India is backed by 5 Domestic Distributing Centres and 
            7 International Ones. Over all these years, we have spread our arms 
            to almost every country to expand our business globally.
          </p>
          <img
            src="https://www.nesscoindia.com/Assets/images/resource/Nessco Map.webp"
            alt="map"
          />
        </div>

        <div className="rightpresence">
          <div className="presenceCard">
            <h3 className="presencecardtitle">Request For A Quote</h3>

            {/* Full Name */}
            <input
              value={formData.Last_Name}
              onChange={handleChange}
              name="Last_Name"
              placeholder="Full Name*"
              className="presenceinpfields"
              type="text"
              required
            />

            {/* Email */}
            <div className="validations" style={{ width: "100%" }}>
              <input
                value={formData.Email}
                onChange={handleChange}
                name="Email"
                placeholder="Email"
                className="presenceinpfields"
                type="text"
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </div>

            {/* Single line: Country dropdown + phone input */}
            <div
              className="validations"
              style={{ width: "100%", display: "flex", gap: "0.5rem" }}
            >
              {/* Country dropdown container => same CSS but custom logic */}
              <div
                className="presenceinpfields"
                ref={dropdownRef} // Attach ref here
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  flex: "0 0 40%",
                }}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {/* Display selected country's flag + code */}
                <div style={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
                  <img
                    src={selectedCountry.flagUrl}
                    alt={`${selectedCountry.iso2} flag`}
                    style={{ width: "20px", height: "14px", objectFit: "cover" }}
                  />
                  <span>{selectedCountry.iso2}</span>
                  <span>({selectedCountry.dial_code})</span>
                </div>
                <span>&#x25BC;</span> {/* arrow down */}

                {/* The dropdown menu */}
                {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "20px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
              </div>

              {/* Local phone input => flexible */}
              <div style={{ flex: 1 }}>
                <input
                  value={localPhone}
                  onChange={handlePhoneChange}
                  name="Mobile"
                  placeholder="Phone Number*"
                  required
                  className="presenceinpfields"
                  type="text"
                />
              </div>
            </div>
            {errors.phoneNumber && <p style={{ color: "red" }}>{errors.phoneNumber}</p>}

            {/* Message */}
            <textarea
              value={formData.Web_Message}
              onChange={handleChange}
              name="Web_Message"
              placeholder="Message"
              className="presenceinpfields"
              cols="30"
              rows="3"
            ></textarea>

           {/* Submit Button */}
{isSubmitting ? (
  <button
    onClick={handleSubmit}
    type="submit"
    className="contactbtn"
    style={{ padding: "1.5rem 2rem" }}
    disabled
  >
    <p className="headerbtncon">Submitting...</p>
  </button>
) : (
  <button
    onClick={handleSubmit}
    type="submit"
    className="contactbtn"
    style={{ padding: "1.5rem 2rem" }}
  >
    <p className="headerbtncon">Send Message</p>
    <FaArrowRightLong 
      className="headerbtnarrow" 
      style={{ fontSize: "1.5rem" }} 
    />
  </button>
)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presence;
