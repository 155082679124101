import React, { useState, useEffect, useRef } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';

// Import your country code data
import countryCodes from '../../Data/phonenocode.json'; // Adjust path if needed

const ContactForm = ({ visitData }) => {
  const navigate = useNavigate();

  // State for form errors
  const [errors, setErrors] = useState({ Mobile: '', Email: '' });

  // State for form data
  const [formData, setFormData] = useState({
    Last_Name: '',
    Email: '',
    Web_Message: '',
  });

  // State for selected country and phone number
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  // State to control the dropdown visibility
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);
  const [isSubmitting, setIsSubmitting] = useState(false);  

  // Ref for the dropdown to detect outside clicks
  const dropdownRef = useRef(null);

  // Determine default country from visitData OR fallback to 'IN'
  useEffect(() => {
    if (visitData?.visitData?.Country_ISO2) {
      const defaultIso2 = visitData.visitData.Country_ISO2;
      const defaultCountry =
        countryCodes.find((c) => c.iso2.toLowerCase() === defaultIso2.toLowerCase()) ||
        countryCodes.find((c) => c.iso2.toLowerCase() === 'in') ||
        countryCodes[0];
      setSelectedCountry(defaultCountry);
    } else {
      // Fallback to 'IN'
      const fallback =
        countryCodes.find((c) => c.iso2.toLowerCase() === 'in') || countryCodes[0];
      setSelectedCountry(fallback);
    }
  }, [visitData]);

  useEffect(() => {
    const filtered = countryCodes.filter(
      (c) =>
        c.iso2.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.dial_code.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredCountries(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  // Handle input changes
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle phone input changes
  const handlePhoneInputChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Handle country selection
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close the dropdown
  };
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false); // Close the dropdown after selection
  };

  // Basic email validation
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validate and format phone number to E.164
  const validateAndFormatPhoneNumber = (fullNumber, countryISO2) => {
    try {
      const phoneNumberObj = parsePhoneNumberFromString(fullNumber, countryISO2);
      return phoneNumberObj?.isValid() ? phoneNumberObj.format('E.164') : null;
    } catch (error) {
      console.error('Error validating phone number:', error);
      return null;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCountry) return; // Guard if data hasn't loaded yet
  
    let valid = true;
    const newErrors = {};
  
    // Combine dial_code + typed phone
    const fullNumber = `${selectedCountry.dial_code}${phoneNumber}`;
    const formattedPhoneNumber = validateAndFormatPhoneNumber(
      fullNumber,
      selectedCountry.iso2
    );
  
    // Validate phone
    if (!phoneNumber) {
      newErrors.Mobile = 'Phone number is required';
      valid = false;
    } else if (!formattedPhoneNumber) {
      newErrors.Mobile = `Invalid phone number for ${selectedCountry.iso2}`;
      valid = false;
    }
  
    // Validate email
    if (!formData.Email) {
      newErrors.Email = 'Email is required';
      valid = false;
    } else if (!validateEmail(formData.Email)) {
      newErrors.Email = 'Email is not valid';
      valid = false;
    }
  
    setErrors(newErrors);
  
    if (valid) {
      setIsSubmitting(true);
      const payload = {
        ...formData,
        Mobile: formattedPhoneNumber, // E.164 format
        ...visitData,
      };
  
    //   // Console log or alert the data
    //   console.log('Form Data:', payload);
    //   alert(`Form Data: ${JSON.stringify(payload, null, 2)}`);
  
    //   // Prevent API call for now
    //   return;
  
      // Uncomment below code to send API request
      try {
        const response = await axios.post(
          'https://website-backend-eosin.vercel.app/form-submission',
          payload,
          { headers: { 'Content-Type': 'application/json' } }
        );
  
          navigate('/thank-you/');
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };
  

  // If the selected country isn't loaded yet, show a loader
  if (!selectedCountry) {
    return <div>Loading country data...</div>;
  }

  return (
    <section className="contact">
      <div className="contactshape"></div>
      <div className="contactcontainer">
        <div className="contactleftsection">
          <p className="contacttitle">GET IN TOUCH</p>
          <h1 className="contactheading">
            Have a Project in Mind? <br /> Let’s Contact
          </h1>
        </div>
        <div className="contactrightsection">
          <form
            className="contactcard"
            onSubmit={handleSubmit}
            name="contactForm"
            id="contactFormid"
          >
            <div className="contactcardtitle">Send a Message</div>
            <div className="contactfields">
              {/* Full Name */}
              <input
                value={formData.Last_Name}
                onChange={handleChange}
                name="Last_Name"
                placeholder="Full Name*"
                className="contactinputfields"
                type="text"
                required
              />

              {/* Email */}
              <div className="validations">
                <input
                  value={formData.Email}
                  onChange={handleChange}
                  name="Email"
                  placeholder="Email"
                  className="contactinputfields"
                  type="email"
                  required
                />
                {errors.Email && <p style={{ color: 'red' }}>{errors.Email}</p>}
              </div>

              {/* Custom dropdown (flags) + phone input */}
              <div className="validations" style={{ display: 'flex', gap: '0.5rem' }}>
                {/* The "selector" for country */}
                <div
                  className="contactinputfields"
                  style={{
                    maxWidth: '120px',
                    paddingLeft: '12px',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                //   onClick={toggleDropdown}
                  ref={dropdownRef} // Attach ref here
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <img
                      src={selectedCountry.flagUrl}
                      alt={`${selectedCountry.iso2} flag`}
                      style={{ width: '20px', height: '14px', objectFit: 'cover' }}
                    />
                    <span>{selectedCountry.iso2}</span>
                    <span>({selectedCountry.dial_code})</span>
                    <span style={{ marginLeft: 'auto' }}>&#x25BC;</span>
                  </div>

                  {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          background: "#fff",
          border: "1px solid #ccc",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}  // Prevent closing dropdown on click
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "none",
            outline: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0.5rem",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredCountries.length > 0 ? (
            filteredCountries.map((c) => (
              <li
                key={c.iso2}
                onClick={() => {
                  handleSelectCountry(c);
                  setShowDropdown(false);
                  setSearchTerm("");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <img
                  src={c.flagUrl}
                  alt={`${c.iso2} flag`}
                  style={{
                    width: "30px",
                    height: "14px",
                    objectFit: "cover",
                  }}
                />
                <span>{c.iso2}</span>
                <span>({c.dial_code})</span>
              </li>
            ))
          ) : (
            <li style={{ padding: "4px 0", textAlign: "center" }}>No results found</li>
          )}
        </ul>
      </div>
    )}
                </div>

                {/* Phone input */}
                <input
                  type="text"
                  placeholder="Phone Number*"
                  className="contactinputfields"
                  value={phoneNumber}
                  onChange={handlePhoneInputChange}
                  required
                  style={{ flex: 1 }}
                />
                 {errors.Mobile && (
    <p style={{ color: 'red', fontSize: '0.9rem', position: 'absolute'}}>
      {errors.Mobile}
    </p>
  )}
              </div>

              
              

              {/* Message */}
              <textarea
                value={formData.Web_Message}
                onChange={handleChange}
                name="Web_Message"
                placeholder="Message"
                className="contactinputtextarea"
                cols="30"
                rows="3"
              />
            </div>

            {/* Submit Button */}
{isSubmitting ? (
  <button
    aria-label="Send Message"
    type="submit"
    className="contactbtn"
    disabled
  >
    <p className="headerbtncon">Submitting...</p>
  </button>
) : (
  <button
    aria-label="Send Message"
    type="submit"
    className="contactbtn"
  >
    <p className="headerbtncon">Send Message</p>
    <FaArrowRightLong
      className="headerbtnarrow"
      style={{ fontSize: '1.5rem' }}
    />
  </button>
)}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
